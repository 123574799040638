import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import './scss/style.scss';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
//IF WE USE HASHROUTER THEN WILL GET # IN THE URL EX.3000/#/DASHBORAD
import { useCookies } from 'react-cookie';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
//LAZY LOAD IMPROVES THE REACT BASED APPLICATION PERFORMANCE
//LAZY LOAD SPLITS THE BUNDLE.JS FILE BASED ON THE ROUTES HERE IT WILL CREATE A SEPARATE FILE FOR BOTH LOGIN AND REGISTER.
// Containers

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
// pages

const HomePage = React.lazy(() => import('./views/AdminViews/HomePage'));
const AdminLogin = React.lazy(() => import('./views/AdminViews/LogIn'));
const AdminDashboard = React.lazy(() => import('./views/AdminViews/AdminDashboard'));
const MemberDetails = React.lazy(() => import('./views/AdminViews/MemberDetails'));
const MemberList = React.lazy(() => import('./views/AdminViews/MemberList'));
const AssessmentList = React.lazy(() => import('./views/AdminViews/Assessments/AssessmentsList'));
const AssessmentAdd = React.lazy(() => import('./views/AdminViews/Assessments/AssessmentsAdd'));

const SuperAdminDashboard = React.lazy(() => import('./views/SuperAdminViews/AdminDashboard'));
const StaffFunctionality= React.lazy(() => import('./views/SuperAdminViews/StaffFunctionality/StaffFunctionality'));
const AddNewRoaster= React.lazy(() => import('./views/SuperAdminViews/StaffFunctionality/RoasterAdd'));
const AddNewTarget= React.lazy(() => import('./views/SuperAdminViews/StaffFunctionality/TargetAdd'));
const AddNewTask= React.lazy(() => import('./views/SuperAdminViews/StaffFunctionality/TaskAdd'));
const AddNewDebriefFile= React.lazy(() => import('./views/SuperAdminViews/StaffFunctionality/DebriefFileAdd'));
const AddNewPaySlip= React.lazy(() => import('./views/SuperAdminViews/StaffFunctionality/PaySlipAdd'));
const AddNewLeave= React.lazy(() => import('./views/SuperAdminViews/StaffFunctionality/LeaveAdd'));

const StaffDashboard = React.lazy(() => import('./views/StaffViews/StaffDashboard'));
const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      <Layout><Component {...props}></Component></Layout>
    )}>
    </Route>
  );
}

const App = () => {
  const [cookies, setCookies, removeCookie] = useCookies(['admin']);


  return (
    // <BrowserRouter>
    <HashRouter>
      <React.Suspense fallback={loading}>
        {/* {!cookies.user_type?(<AdminLogin/>):( */}
        <Switch>
          <Route exact path="/" name="Home Page" render={props => <HomePage {...props} />} />

          <Route exact path="/login" name="Login Page" render={props => <AdminLogin {...props} />} />
          <Route exact path="/admindashboard" name="Admin Dashboard" render={props => <AdminDashboard {...props} />} />
          <Route exact path="/memberdetails" name="Admin Dashboard" render={props => <MemberDetails {...props} />} />
          <Route exact path="/memberlist" name="Admin Dashboard" render={props => <MemberList {...props} />} />
          <Route exact path="/asslist" name="Admin Dashboard" render={props => <AssessmentList {...props} />} />
          <Route exact path="/assadd" name="Admin Dashboard" render={props => <AssessmentAdd {...props} />} />


          <Route exact path="/superadmindashboard" name="Super Admin Dashboard" render={props => <SuperAdminDashboard {...props} />} />
          <Route exact path="/staffaddfunctionality" name="Super Admin Dashboard" render={props => <StaffFunctionality {...props} />} />
          <Route exact path="/roasteradd" name="Super Admin Dashboard" render={props => <AddNewRoaster {...props} />} />
          <Route exact path="/targetadd" name="Super Admin Dashboard" render={props => <AddNewTarget {...props} />} />
          <Route exact path="/taskadd" name="Super Admin Dashboard" render={props => <AddNewTask {...props} />} />
          <Route exact path="/debrieffileadd" name="Super Admin Dashboard" render={props => <AddNewDebriefFile {...props} />} />
          <Route exact path="/payslipadd" name="Super Admin Dashboard" render={props => <AddNewPaySlip {...props} />} />
          <Route exact path="/leaveadd" name="Super Admin Dashboard" render={props => <AddNewLeave {...props} />} />

          <Route exact path="/staffdashboard" name="Super Admin Dashboard" render={props => <StaffDashboard {...props} />} />
          
          <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />

          {/* <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
          {/* <Route path="/dashboard" name="Home" render={props => <TheLayout {...props}/>} /> */}
          {/* :null} */}
          {/* {cookies.user_dashboard_id==="OPERATORS_DASHBOARD"? */}
          {/* <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
          {/* <AppRoute path= '/operatordashboard' name= 'OperatorDashboard' layout={TheLayoutOperator} component={OperatorDashboard} /> */}

          {/* :null} */}

        </Switch>
        {/* )} */}
      </React.Suspense>
      <ToastContainer
        draggable={false}
        transition={Zoom}//zoom out
        autoClose={2000}//closing after 8sec
        position="top-center"
      />
      {/* </BrowserRouter> */}
    </HashRouter>
  );
}


export default App;
